import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { ApplicationService } from './application.service';
import { ConfigService } from './config.service';
import { LogService } from './log.service';
import { LoginService } from './login.service';

import { io } from 'socket.io-client';
import { AppNotificationsSettings } from '../structures/app-notifications-settings';
import { log } from './decorators/log.decorator';

/**
 *  service qui gere les notifications dans l'application.
 */
@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    io: any;
    socket: any;

    mouseMoved: boolean;

    closeCurrentCall: Subject<any> = new Subject();
    openCall: Subject<any> = new Subject();

    newNotificationReceived: Subject<any> = new Subject();
    notificationRead: Subject<any> = new Subject();
    notificationDeleted: Subject<any> = new Subject();

    newChatReceived: Subject<string> = new Subject();
    chatCountUpdated: Subject<number> = new Subject();

    allNotificationRead: Subject<void> = new Subject();
    allNotificationDeleted: Subject<void> = new Subject();

    connectionStatusChanged: Subject<any> = new Subject();

    constructor(
        private http: HttpClient,
        private applicationService: ApplicationService,
        private loginService: LoginService,
        private configService: ConfigService
    ) {}

    init(): void {
        this.socket = io(this.configService.getNotificationEndPoint(), {
            path: '/socket.io',
            withCredentials: true
        });

        if (this.loginService.getUser()) {
            if (this.loginService.getUser().connectionStatus === 'online') {
                this.emitNotifications('online');
            } else if (this.loginService.getUser().connectionStatus === 'donotdisturb') {
                this.emitNotifications('donotdisturb');
            }
        }

        this.onNotificationReceived().subscribe((notification: any) => {
            if (notification.type === 'user') {
                this.parseUserNotification(notification);
            } else if (notification.type === 'system') {
                this.parseSystemNotification(notification);
            }
        });

        this.mouseMoved = false;

        setInterval(
            () => {
                if (!this.mouseMoved) {
                    this.emitIdleStart();
                }
                this.mouseMoved = false;
            },
            this.configService.getConfig().idle_delay * 60 * 1000
        );

        this.onConnectionStatusChangeReceived();
    }

    /**
     *  observable qui va recevoir les donnees de socket io, les stocker et passer a la data suivante a ecouter.
     * @returns {Observable<any>} l'observable a souscrire pour recuperer la data et donc la notification.
     */
    @log() onNotificationReceived(): Observable<any> {
        return new Observable<any>((observer) => {
            this.socket.on('notification', (data: any) => {
                observer.next(data);
                window.top.postMessage(data, '*');
            });
        });
    }

    @log() onConnectionStatusChangeReceived() {
        this.socket.on('connectionstatuschanged', (data: any) => {
            this.connectionStatusChanged.next(data);
            if (data.uniqueid === this.loginService.getUser().uniqueid) {
                this.loginService.getUser().connectionStatus = data.connectionstatus;
            }
            window.parent.postMessage(data, '*');
        });
    }

    @log() emitNotifications(value: string) {
        if (this.socket) {
            this.socket.emit(value, value);
        }
    }

    /**
     *  s'occupe du traitement des notifications utilisateur.
     * @param notif la notification a traiter.
     * @returns {void}
     */
    parseUserNotification(notification: any): void {
        this.emitNewUserNotification(notification);
        if (notification.app_id === this.applicationService.getCurrentApplication().app_id) {
            window.parent.postMessage('refresh', '*');
        }
    }

    /**
     *  s'occupe du traitement des notifications systeme.
     * @param notif la notification a traiter.
     * @returns {void}
     */
    parseSystemNotification(notification: any): void {
        switch (notification.app_id) {
            case 'easitraining': {
                if (this.applicationService.getCurrentApplication().app_id === 'easimedia') {
                    window.parent.postMessage('refresh media content', '*');
                }
                break;
            }
            case 'easiconnect': {
                switch (notification.title) {
                    case 'all notifications marked as read': {
                        this.emitAllNotificationsRead();
                        break;
                    }
                    case 'all notifications deleted': {
                        this.emitAllNotificationsDeleted();
                        break;
                    }
                }
                if (notification.markasread === 1) {
                    this.emitNotificationRead(notification);
                } else if (notification.deleted === true) {
                    this.emitNotificationDeleted(notification);
                }
                break;
            }
            case 'easicall': {
                switch (notification.title) {
                    case 'Nouvel appel':
                    case 'Appel manqué': {
                        const message = JSON.parse(notification.metadata);
                        if (message.users.to === this.loginService.getUser().id) {
                            this.closeCurrentCall.next();
                            this.openCall.next(JSON.parse(notification.metadata));
                        }
                        break;
                    }
                    case 'Appel refusé': {
                        const message = JSON.parse(notification.metadata);
                        if (message.users.from === this.loginService.getUser().id) {
                            this.closeCurrentCall.next();
                            this.openCall.next(JSON.parse(notification.metadata));
                        }
                        break;
                    }
                    case 'Appel annulé': {
                        this.closeCurrentCall.next();
                        break;
                    }
                    case 'Appel accepté': {
                        if (document.hasFocus()) {
                            window.open(JSON.parse(notification.metadata).urlfrom, '_blank');
                        }
                        this.closeCurrentCall.next();
                        break;
                    }
                }
                break;
            }
            case 'easichat': {
                switch (notification.title) {
                    case 'user_message_count has been updated': {
                        this.emitChatCountUpdated(notification);
                        break;
                    }
                    case 'discussion_user_message_count has been updated':
                    case 'message update':
                    case 'message delete': {
                        if (
                            notification.app_id === 'easichat' &&
                            this.applicationService.getCurrentApplication() &&
                            this.applicationService.getCurrentApplication().app_id === 'easichat'
                        ) {
                            window.parent.postMessage(notification.body, '*');
                        } else {
                            this.emitChatReceived(notification);
                        }
                        break;
                    }
                }
                break;
            }
        }
    }

    /**
     *  observable qui recupere la liste des notifications depuis le web service prevu a cet effet.
     * @param params les parametres de la requete a envoyer au web service.
     * @returns {Observable<any>} l'observable qui recupere la liste des notifications.
     */
    @log() getMyNotifications(params: any): Observable<any> {
        return this.http.get('/notifications', { params });
    }

    /**
     *  observable qui recupere le nombre de notifications non lues depuis le web service prevu a cet effet.
     * @returns {Observable<any>} l'observable qui recupere le nombre de notifications non lues.
     */
    @log() getMyNbNotification(): Observable<any> {
        return this.http.get('/notifications?count');
    }

    /**
     *  observable qui marque comme lue la notification envoyee via le web service prevu a cet effet.
     * @param idNotification l'identifiant de la notification a marquer comme lue.
     * @returns {Observable<any>} l'observable qui gere l'envoi de la notification.
     */
    @log() markAsRead(idNotification: number): Observable<any> {
        return this.http.post('/notifications/' + idNotification + '/read', {});
    }

    /**
     *  observable qui marque comme lue la notification envoyee via le web service prevu a cet effet.
     * @returns {Observable<any>} l'observable qui gere l'envoi de la notification.
     */
    @log() markAllAsRead(): Observable<any> {
        return this.http.put('/notifications/mark_all_read', {});
    }

    /**
     *  observable qui marque comme lue la notification envoyee via le web service prevu a cet effet.
     * @returns {Observable<any>} l'observable qui gere l'envoi de la notification.
     */
    @log() hasUnreadNotifications(): Observable<any> {
        return this.http.get('/notifications/has_unread');
    }

    /**
     *  observable qui marque comme lue la notification envoyee via le web service prevu a cet effet.
     * @returns {Observable<any>} l'observable qui gere l'envoi de la notification.
     */
    @log() deleteAllNotifications(): Observable<any> {
        return this.http.delete('/notifications/delete_all');
    }

    /**
     *  observable qui supprime une notification via le web service prevu a cet effet.
     * @param idNotification l'identifiant de la notification a supprimer.
     * @returns {Observable<any>} l'observable qui gere l'envoi de la notification.
     */
    @log() deleteNotification(idNotification: number): Observable<any> {
        return this.http.delete('/notifications/' + idNotification);
    }

    /**
     *  observable qui recupere la liste des canaux de notifications de l'utilisateur
     * @returns {Observable<AppNotificationsSettings[]>} l'observable qui recupere la liste des canaux.
     */
    @log() getMyChannels(): Observable<Array<AppNotificationsSettings>> {
        return this.http.get('/notifications/channels').pipe(
            // Replace l'élément easi news en début de tableau si pas fait par le back
            map((data: Array<AppNotificationsSettings>) => {
                const easinewsIndex: number = data.findIndex((app) => app.app_id === 'easinews');
                if (easinewsIndex > 0) {
                    const easinewsApp: AppNotificationsSettings = data[easinewsIndex];
                    data.splice(easinewsIndex, 1);
                    data.unshift(easinewsApp);
                }
                return data;
            })
        );
    }

    /**
     *  action qui met à jour les canaux de notifications de l'utilisateur
     * @returns {Observable<any>} A VOIR CE QU'ON RECOIT
     */
    @log() setMyChannels(channels): Observable<any> {
        const body = [...channels];

        return this.http.put('/notifications/channels', body);
    }

    markMouseAsMoved() {
        if (this.loginService.getUser()) {
            if (this.loginService.getUser().connectionStatus === 'idle') {
                this.emitIdleEnd();
            }
            this.mouseMoved = true;
        }
    }

    emitIdleStart() {
        if (this.loginService.getUser()) {
            if (this.loginService.getUser().connectionStatus !== 'donotdisturb') {
                this.loginService.getUser().connectionStatus = 'idle';
                this.emitNotifications('idle');
            }
        }
    }

    emitIdleEnd() {
        if (this.loginService.getUser()) {
            if (this.loginService.getUser().connectionStatus !== 'donotdisturb') {
                this.loginService.getUser().connectionStatus = 'online';
                this.emitNotifications('online');
            }
        }
    }

    emitNewUserNotification(notification: any) {
        this.newNotificationReceived.next(notification);
    }

    emitChatReceived(chat: any): void {
        this.newChatReceived.next(chat);
    }

    emitNotificationRead(notification: any): void {
        this.notificationRead.next(notification);
    }

    emitNotificationDeleted(notification: any): void {
        this.notificationDeleted.next(notification);
    }

    emitChatCountUpdated(message_count: number) {
        this.chatCountUpdated.next(message_count);
    }

    emitAllNotificationsRead() {
        this.allNotificationRead.next();
    }

    emitAllNotificationsDeleted() {
        this.allNotificationDeleted.next();
    }
}
