import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';

import { EasiHttpParams } from '../interceptors/easi-http-params';

import { log } from './decorators/log.decorator';

/**
 *  service gerant l'utilisateur actuel dans l'application.
 */
@Injectable({
    providedIn: 'root'
})
export class PlanningService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService
    ) {}

    @log() getDayWatch(): Observable<any> {
        return this.http.get(
            `${this.configService.getConfig().endpoint.planning}/getuser/GetDaywatch`,
            {
                responseType: 'text',
                params: new EasiHttpParams({
                    overrideBaseUrl: true,
                    noError: true
                })
            }
        );
    }

    @log() getN1Maintenance(): Observable<any> {
        return this.http.get(
            `${this.configService.getConfig().endpoint.planning}/getuser/GetN1Maintenance`,
            {
                responseType: 'text',
                params: new EasiHttpParams({
                    overrideBaseUrl: true,
                    noError: true
                })
            }
        );
    }

    @log() getN2Maintenance(): Observable<any> {
        return this.http.get(
            `${this.configService.getConfig().endpoint.planning}/getuser/GetN2Maintenance`,
            {
                responseType: 'text',
                params: new EasiHttpParams({
                    overrideBaseUrl: true,
                    noError: true
                })
            }
        );
    }
}
