import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { LoginService } from '../../../services/login.service';
import { ChatService } from '../../../services/chat.service';

@Component({
    selector: 'app-chat-entry',
    templateUrl: './chat-entry.component.html',
    styleUrls: ['./chat-entry.component.scss'],
    standalone: false
})
export class ChatEntryComponent implements OnInit {
    @Input() message: any;
    @Input() last: boolean;
    @Input() chat: any;
    @Output() clickEditMessage: EventEmitter<any> = new EventEmitter();

    author: string;
    timestamp: string;
    content: string[] = [];
    links: string[] = [];
    position = 'undefined';
    disabled: boolean;
    initials = '';
    nbRead: number;
    nbNotRead: number;
    discussionId: number;
    isBeingEdited: boolean;

    constructor(
        private loginService: LoginService,
        private chatService: ChatService
    ) {}

    ngOnInit() {
        if (this.loginService.getUser()) {
            this.initMessage();
        }
    }

    initMessage() {
        if (!this.message.deleted) {
            this.disabled = this.message.id === -1;
            this.isBeingEdited = false;
            this.author =
                this.message.author.lastname.toUpperCase() + ' ' + this.message.author.firstname;
            this.initials =
                this.message.author.lastname.toUpperCase().charAt(0) +
                this.message.author.firstname.charAt(0);
            this.timestamp = new Date(this.message.timecreated).toLocaleString('fr-FR');
            this.nbRead = this.message.read;
            this.nbNotRead = this.message.notread;
            this.parseHttpsLink(this.message.message);
            this.discussionId = this.message.discussionid;
        } else {
            if (this.message.author) {
                this.author =
                    this.message.author.lastname.toUpperCase() +
                    ' ' +
                    this.message.author.firstname;
                this.initials =
                    this.message.author.lastname.toUpperCase().charAt(0) +
                    this.message.author.firstname.charAt(0);
            }
            this.timestamp = new Date(this.message.timecreated).toLocaleString('fr-FR');
        }

        if (this.message.author) {
            this.position =
                this.loginService.getUser().id === this.message.author.id
                    ? 'position-right'
                    : 'position-left';
        } else {
            this.position = 'position-right';
        }
    }

    private parseHttpsLink(message: string) {
        let i: number;

        while ((i = message.indexOf('https://')) > -1) {
            this.content.push(message.substring(0, i));
            message = message.slice(i);
            if ((i = message.search(/\s/)) > -1) {
                this.links.push(message.substring(0, i));
                message = message.slice(i);
            } else {
                this.links.push(message);
                return;
            }
        }
        if (message) {
            this.content.push(message);
        }
    }

    isCurrentOwner(): boolean {
        if (this.message.author) {
            return this.loginService.getUser()
                ? this.loginService.getUser().id === this.message.author.id
                : false;
        } else {
            return false;
        }
    }

    isRead(): boolean {
        return this.message.readOn !== null;
    }

    getFormattedSize(size: number): String {
        if (size > 1000000) {
            return Math.floor(size / 1000000) + 'Mo';
        } else if (size > 1000) {
            return Math.floor(size / 1000) + 'Ko';
        } else {
            return Math.floor(size) + 'o';
        }
    }

    isImage(attachment): boolean {
        return (
            ['jpg', 'jpeg', 'gif', 'png', 'apng', 'svg', 'bmp'].indexOf(
                attachment.extension.toLowerCase()
            ) > -1
        );
    }

    getAttachmentUrl(attachment): string {
        return (
            this.chatService.getChatBackendEndPoint() +
            '/messages/' +
            this.message.id +
            '/attachments/' +
            attachment.name
        );
    }

    downloadAttachment(attachmentName: string) {
        return (
            this.chatService.getChatBackendEndPoint() +
            '/messages/' +
            this.message.id +
            '/attachments/' +
            encodeURIComponent(attachmentName)
        );
    }

    editMessage() {
        this.isBeingEdited = true;
        this.clickEditMessage.emit(this.message);
    }

    cancelEdition() {
        this.message.isEditing = false;
        this.isBeingEdited = false;
    }
}
