// Internal dependencies
import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { TooltipModule } from 'uimm-tooltip';

// Components

// Services
import { ApplicationService } from '@/services/application.service';

// Interfaces

// Pipes

// Interface
import { Application } from '@/structures/application';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-application',
    templateUrl: './application.component.html',
    styleUrls: ['./application.component.scss'],
    imports: [TooltipModule, CommonModule]
})
export class ApplicationComponent {
    application = input<Application>();
    isChild = input<boolean>(false);

    constructor(private applicationService: ApplicationService) {}

    // #region Getters

    getApplicationName(): string {
        if (this.isChild()) {
            return this.application().frontend_url.replace(/^(http|https):\/\//g, '');
        } else {
            return this.application().name;
        }
    }

    getApplicationURL(): string {
        if (!this.application().children) {
            return this.application().login_url
                ? this.application().login_url
                : this.application().frontend_url;
        }
        return '#';
    }

    isActiveApp() {
        if (this.applicationService.getCurrentApplication()) {
            return (
                this.application().app_id === this.applicationService.getCurrentApplication().app_id
            );
        }
    }

    // #endregion Getters

    // #region Handlers

    // #endregion Handlers

    // #region Internals

    // #endregion Internals
}
