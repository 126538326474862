// Internal dependencies
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { TooltipModule } from 'uimm-tooltip';

// Components
import { ApplicationComponent } from './application/application.component';

// Services
import { ApplicationService } from '@/services/application.service';
import { IframeService } from '@/services/iframe.service';
import { LoginService } from '@/services/login.service';

// Interfaces

// Pipes

// Interface
import { Application } from '@/structures/application';

interface Section {
    name: string;
    applications: Array<Application>;
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-applications',
    templateUrl: './applications.component.html',
    styleUrls: ['./applications.component.scss'],
    imports: [TooltipModule, CommonModule, ApplicationComponent]
})
export class ApplicationsComponent implements OnInit {
    sections: Array<Section> = [];

    constructor(
        private applicationService: ApplicationService,
        private loginService: LoginService,
        private iframeService: IframeService
    ) {}

    ngOnInit() {
        this.sections = [];
        if (this.loginService.getUser()) {
            this.applicationService
                .getApplications()
                .subscribe((applications: Array<Application>) => {
                    const groupes = {};

                    applications
                        .filter(
                            (application: Application) =>
                                application.section !== null && application.section !== 'null'
                        )
                        .map((application: Application) => {
                            const section = application.section;

                            if (!groupes[section]) {
                                groupes[section] = [];
                            }

                            const applicationExistante = groupes[section].find(
                                (app: Application) => app.name === application.name
                            );

                            if (applicationExistante) {
                                if (!applicationExistante.children) {
                                    applicationExistante.children = [
                                        { ...applicationExistante, children: null }
                                    ];
                                }
                                applicationExistante.children.push(application);
                                applicationExistante.children.sort(
                                    (a: Application, b: Application) =>
                                        a.frontend_url.localeCompare(b.frontend_url)
                                );
                            } else {
                                groupes[section].push(application);
                            }
                        });

                    this.sections = Object.keys(groupes).map((section) => ({
                        name: section,
                        applications: groupes[section]
                    }));
                });
        }
    }

    // #region Getters

    isOpened(): boolean {
        return this.iframeService.isOpenedComponent('ApplicationsComponent');
    }

    // #endregion Getters

    // #region Handlers

    // #endregion Handlers

    // #region Internals

    // #endregion Internals
}
