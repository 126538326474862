import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApplicationService } from '../../services/application.service';
import { LoginService } from '../../services/login.service';
import { EventService } from '../../services/event.service';
import { NotificationService } from '../../services/notification.service';
import { ConfigService } from '../../services/config.service';
import { IframeService } from '../../services/iframe.service';
import { DialogService } from '../../services/dialog.service';
import { NewsService } from '../../services/news.service';
import { PlanningService } from '../../services/planning.service';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Application } from 'src/app/structures/application';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent implements OnInit {
    /**
     * @param {any} rightIcons pour stocker les icones de droite de l'utilisateur.
     * @param {any} centerIcons pour stocker les icones du centre de l'utilisateur.
     * @param {number} nbMessages le nombre de messages non lus.
     * @param {number} nbNotification le nombre de notifications non lues.
     * @param {boolean} helpIsEnabled tester si l'aide est activee et visible ou pas.
     * @param {Output} message emet l'evenement pour ouvrir la popup message.

     * @param {number} numberNewsUnseen nombre de news non lues.
     */
    location: string;
    rightIcons: any;
    centerIcons: any;
    nbMessages: number;
    nbNotification: number;
    helpIsEnabled: boolean;
    numberNewsUnseen: number;
    newsLoaded: boolean;
    currentDate: Date;

    daywatchUser: string;
    maintenanceN1User: string;
    maintenanceN2User: string;

    subscriptions = new Subscription();

    applications: Array<Application>;

    constructor(
        private applicationService: ApplicationService,
        private loginService: LoginService,
        private eventService: EventService,
        private notificationService: NotificationService,
        private configService: ConfigService,
        private iframeService: IframeService,
        private dialogService: DialogService,
        private planningService: PlanningService,
        private newsService: NewsService
    ) {
        this.location = window.location.href;
    }

    ngOnInit() {
        this.numberNewsUnseen = 0;
        this.helpIsEnabled = true;
        this.newsLoaded = false;
        this.currentDate = new Date();

        if (this.loginService.getUser()) {
            this.nbMessages = this.loginService.getUser().userMessageCount;
            this.subscriptions.add(
                this.applicationService
                    .getApplications()
                    .subscribe((applications: Array<Application>) => {
                        this.applications = applications;
                        this.eventService.getParentURL();
                    })
            );

            if (this.displayPlanning()) {
                this.planningService.getDayWatch().subscribe((daywatchUser: any) => {
                    this.daywatchUser = daywatchUser;
                });
                this.planningService.getN1Maintenance().subscribe((maintenanceN1User: any) => {
                    this.maintenanceN1User = maintenanceN1User;
                });
                this.planningService.getN2Maintenance().subscribe((maintenanceN2User: any) => {
                    this.maintenanceN2User = maintenanceN2User;
                });
            }

            setTimeout(() => {
                this.subscriptions.add(
                    this.notificationService.getMyNbNotification().subscribe((data: any) => {
                        if (this.nbNotification === 0) {
                            this.nbNotification = null;
                        } else {
                            this.nbNotification = data.count;
                        }
                    })
                );
            }, this.configService.getConfig().notification_delay * 1000);
        }

        // ICONS

        this.helpIsEnabled = this.configService.getConfig().helpVisible;
        this.subscriptions.add(
            this.loginService.iconsLoaded.subscribe(() => {
                this.rightIcons = this.loginService.getRightIconsForCurrentUser();
                this.centerIcons = this.loginService.getCenterIconsForCurrentUser();
                this.helpIsEnabled =
                    this.loginService.getHelpState() && this.configService.getConfig().helpVisible;
            })
        );

        // NEWS

        this.subscriptions.add(
            this.newsService.numberNewsUnseen.subscribe((numberNewsUnseen: number) => {
                this.numberNewsUnseen = numberNewsUnseen;
            })
        );

        this.subscriptions.add(
            this.newsService.newsRead.subscribe(() => {
                if (this.numberNewsUnseen > 0) {
                    this.numberNewsUnseen--;
                }
            })
        );

        this.subscriptions.add(
            this.newsService.allNewsRead.subscribe(() => {
                this.numberNewsUnseen = 0;
            })
        );

        // NOTIFICATIONS

        this.subscriptions.add(
            this.notificationService.newNotificationReceived.subscribe((notification: any) => {
                if (!notification.duplicate) {
                    this.nbNotification++;
                }
            })
        );

        this.subscriptions.add(
            this.notificationService.notificationRead.subscribe(() => {
                this.nbNotification--;
            })
        );

        this.subscriptions.add(
            this.notificationService.allNotificationRead.subscribe(() => {
                this.nbNotification = 0;
            })
        );

        // MESSAGES

        this.subscriptions.add(
            this.notificationService.newChatReceived.subscribe(() => {
                this.nbMessages++;
            })
        );

        this.subscriptions.add(
            this.notificationService.chatCountUpdated.subscribe((notification: any) => {
                this.nbMessages = notification.body.message_count;
            })
        );
    }

    /**
     * Recupere le fichier de config, le style et l'applique ou non sur l'icone de gauche du header.
     * @returns {string} le style css a appliquer.
     */
    getHeaderIconStyle(): string {
        let style = null;
        const config = this.configService.getConfig();

        if (config.icon_header.isModified) {
            style = config.icon_header.style;
        } else {
            style = {
                color: this.getApplicationColor(),
                'background-color': 'white'
            };
        }
        return style;
    }

    getHeaderStripe(): boolean {
        return this.configService.getConfig().header.stripe;
    }

    /**
     * @returns {any} le nom de l'application courante.
     */
    getServerName(): any {
        return this.applicationService.getCurrentApplication()
            ? this.applicationService.getCurrentApplication().name
            : '';
    }

    /**
     * @returns {string} la couleur de l'application courante.
     */
    getApplicationColor(): string {
        if (this.applicationService.getCurrentApplication()) {
            return this.applicationService.getCurrentApplication().color;
        } else {
            return '#424242';
        }
    }

    /**
     * @returns {any} le nombre de notifications non lues.
     */
    getNbNotification(): any {
        if (this.nbNotification === 0) {
            return null;
        } else if (this.nbNotification < 99) {
            return this.nbNotification;
        } else if (this.nbNotification) {
            return '++';
        }
    }

    /**
     * @returns {any} le nombre de messages non lus.
     */
    getNbMessage(): any {
        if (this.nbMessages === 0) {
            return null;
        } else if (this.nbMessages < 99) {
            return this.nbMessages;
        } else if (this.nbMessages) {
            return '++';
        }
    }

    /**
     * @returns {any} le prenom de l'utilisateur.
     */
    getUserFirstname(): any {
        return this.loginService.getUser() ? this.loginService.getUser().firstname : '';
    }

    getAvatar(): any {
        if (this.loginService.getUser()) {
            if (this.loginService.getUser().avatar !== 'https://dev-backend.easi-connect.fr') {
                return this.loginService.getUser().avatar;
            }
        }
        return false;
    }

    getUserInitial() {
        if (this.loginService.getUser()) {
            return (
                this.loginService.getUser().firstname.charAt(0).toUpperCase() +
                this.loginService.getUser().lastname.toUpperCase().charAt(0).toUpperCase()
            );
        }
        return '';
    }

    getConnectionStatus() {
        if (this.loginService.getUser()) {
            if (this.loginService.getUser().connectionStatus === 'online') {
                return 'green';
            } else if (this.loginService.getUser().connectionStatus === 'idle') {
                return 'orange';
            } else if (this.loginService.getUser().connectionStatus === 'donotdisturb') {
                return 'red';
            }
        }
    }

    /**
     *  permet d'emettre l'evenement pour ouvrir le menu utilisateur.
     * @returns {void}
     */
    toggleUserMenu($event: Event): void {
        this.iframeService.toggleComponent('UserComponent');
    }

    toggleConnectionStatus() {
        if (this.loginService.getUser()) {
            if (this.loginService.getUser().connectionStatus === 'donotdisturb') {
                this.loginService.setDefaultConnectionStatus('online').subscribe(() => {
                    this.notificationService.emitNotifications('online');
                    this.loginService.getUser().connectionStatus = 'online';
                });
            } else {
                this.loginService.setDefaultConnectionStatus('donotdisturb').subscribe(() => {
                    this.notificationService.emitNotifications('donotdisturb');
                    this.loginService.getUser().connectionStatus = 'donotdisturb';
                });
            }
        }
    }

    displayPlanning() {
        return this.configService.getConfig().displayPlanning;
    }

    showToggleIcons() {
        return this.loginService.getUser();
    }

    /**
     *  permet d'emettre l'evenement pour ouvrir le menu des applications.
     * @returns {void}
     */
    toggleApplicationMenu(): void {
        this.iframeService.toggleComponent('ApplicationsComponent');
    }
    /**
     *  permet d'emettre l'evenement pour ouvrir le menu des icones.
     * @returns {void}
     */
    toggleIconsMenu(): void {
        this.iframeService.toggleComponent('IconMobileComponent');
    }

    /**
     *  permet d'emettre l'evenement pour ouvrir le menu des notifications.
     * @returns {void}
     */
    toggleNotificationMenu(): void {
        this.iframeService.toggleComponent('NotificationComponent');
    }

    /**
     *  poste a l'application le message d'aller sur une route.
     * @returns {void}
     */
    goTo(route: string): void {
        if (route === 'newsList') {
            this.iframeService.openComponent('NewsComponent');
        } else {
            window.parent.postMessage(route, '*');
        }
    }

    openHelp(): void {
        this.iframeService.isOpenedComponent('HelpComponent')
            ? this.iframeService.closeComponent('HelpComponent')
            : this.iframeService.openComponent('HelpComponent');
    }

    /**
     *  verifie si l'utilisateur a acces a easi-chat.
     * @returns {boolean}
     */
    hasChatAccess() {
        for (const key in this.applications) {
            if (this.applications[key].app_id === 'easichat') {
                return true;
            }
        }
        return false;
    }

    /**
     *  ouvre l'application easi-chat.
     * @returns {void}
     */
    goToChat(): void {
        if (this.applicationService.getCurrentApplication().app_id !== 'easichat') {
            for (const key in this.applications) {
                if (this.applications[key].app_id === 'easichat') {
                    window.open(this.applications[key].frontend_url, '_blank');
                }
            }
        }
    }
}
