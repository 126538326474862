<div id="shared-root" *ngIf="appReady && displayHeader()" [attr.data-loaded]="isLoading()">
    <app-header></app-header>
    <app-user></app-user>
    <app-news></app-news>
    <app-applications></app-applications>
    <app-notification></app-notification>
    <app-notification-popup></app-notification-popup>
    <app-notifications-manager></app-notifications-manager>
    <app-chat-popup></app-chat-popup>
    <app-icon-mobile></app-icon-mobile>
    <app-help></app-help>
    <app-flash-message></app-flash-message>
</div>

<iframe [src]="iframeUrl" style="display: none"></iframe>
