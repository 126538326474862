import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { ChatComponent } from '../dialogs/chat/chat.component';
import { ErrorComponent } from '../dialogs/error/error.component';
import { WarningComponent } from '../dialogs/warning/warning.component';
import { UserDetailsComponent } from '../dialogs/user-details/user-details.component';
import { LogoutComponent } from '../dialogs/logout/logout.component';
import { AzureConfirmationComponent } from '../dialogs/azure-confirmation/azure-confirmation.component';
import { HelpContentComponent } from '../dialogs/help-content/help-content.component';
import { TutorUsersComponent } from 'src/app/dialogs/tutor-users/tutor-users.component';

import { IframeService } from './iframe.service';
import { FlashMessageService } from './flash-message.service';
import { NotificationService } from './notification.service';

import { User } from '../structures/user';
import { Group } from 'src/app/structures/group';

/**
 *  service qui gere les dialogues et leurs interractions.
 */
@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(
        private iframeService: IframeService,
        private flashMessageService: FlashMessageService,
        private notificationService: NotificationService,
        private dialog: MatDialog
    ) {
        this.notificationService.openCall.subscribe((data) => {
            this.openCall(data);
        });
    }

    /**
     *  ouvre la fenetre de dialogue de logout.
     */
    async openLogoutDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        this.iframeService.openComponent('LogoutComponent');
        this.dialog
            .open(LogoutComponent, dialogConfig)
            .afterClosed()
            .subscribe((data) => {
                this.iframeService.closeComponent('LogoutComponent');
            });
    }

    /**
     *  ouvre la fenetre de dialogue de logout.
     */
    async openLoginTimeoutDialog() {
        const { LoginTimeoutComponent } = await import(
            '../dialogs/login-timeout/login-timeout.component'
        );
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        this.iframeService.openComponent('LoginTimeoutComponent');
        this.dialog
            .open(LoginTimeoutComponent, dialogConfig)
            .afterClosed()
            .subscribe((data) => {
                this.iframeService.closeComponent('LoginTimeoutComponent');
            });
    }

    /**
     *  ouvre la fenetre de dialogue de logout.
     */
    async openTokenManagerDialog() {
        const { TokenManagerComponent } = await import(
            '../dialogs/token-manager/token-manager.component'
        );
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        this.iframeService.openComponent('TokenManagerComponent');
        const ref: MatDialogRef<any> = this.dialog.open(TokenManagerComponent, dialogConfig);
        ref.afterClosed().subscribe(() => {
            this.iframeService.closeComponent('TokenManagerComponent');
        });
    }

    async openMobileAppDialog() {
        const { MobileAppComponent } = await import('../dialogs/mobile-app/mobile-app.component');
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        this.iframeService.openComponent('MobileAppComponent');
        const ref: MatDialogRef<any> = this.dialog.open(MobileAppComponent, dialogConfig);
        ref.afterClosed().subscribe(() => {
            this.iframeService.closeComponent('MobileAppComponent');
        });
    }

    async openChangePhotoDialog() {
        const { ChangePhotoComponent } = await import(
            '../dialogs/change-photo/change-photo.component'
        );
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        this.iframeService.openComponent('ChangePhotoComponent');
        const ref: MatDialogRef<any> = this.dialog.open(ChangePhotoComponent, dialogConfig);
        ref.afterClosed().subscribe(() => {
            this.iframeService.closeComponent('ChangePhotoComponent');
        });
    }

    async openUnreadNewsDialog(news: Array<any>) {
        const { UnreadNewsComponent } = await import(
            '../dialogs/unread-news/unread-news.component'
        );

        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '1000px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        dialogConfig.data.news = news;

        this.iframeService.openComponent('UnreadNewsComponent');
        const ref: MatDialogRef<any> = this.dialog.open(UnreadNewsComponent, dialogConfig);
        ref.afterClosed().subscribe(() => {
            this.iframeService.closeComponent('UnreadNewsComponent');
        });
    }

    /**
     * @param {string} content Le message d'erreur que l'on souhaite afficher
     * Ouvre la fenetre d'erreur
     */
    openErrorDialog(content?: String) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        if (content) {
            dialogConfig.data = content;
        }

        this.iframeService.openComponent('ErrorComponent');
        const ref: MatDialogRef<ErrorComponent> = this.dialog.open(ErrorComponent, dialogConfig);
        ref.afterClosed().subscribe(() => {
            this.iframeService.closeComponent('ErrorComponent');
        });
    }

    openAzureConfirmation(): Observable<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        const ref: MatDialogRef<AzureConfirmationComponent> = this.dialog.open(
            AzureConfirmationComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    openContentDialog(content: any) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.maxWidth = '95vw';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = content;

        this.dialog.open(HelpContentComponent, dialogConfig);
    }

    async openVideoDialog(video: any) {
        const { HelpVideoComponent } = await import('../dialogs/help-video/help-video.component');

        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.maxWidth = '95vw';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            name: video.name,
            url: video.video,
            description: video.description
        };

        this.dialog.open(HelpVideoComponent, dialogConfig);
    }

    openTutorUsers(data: {
        viewOnly: boolean;
        tutorUsers: Array<User>;
        tutorGroups: Array<Group>;
        structureid: number;
    }) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.maxWidth = '1200px';
        dialogConfig.height = '85vh';
        dialogConfig.panelClass = 'new-dialog';
        dialogConfig.data = data;

        const ref: MatDialogRef<any> = this.dialog.open(TutorUsersComponent, dialogConfig);
        return ref.afterClosed();
    }

    openUserDetails(userId: any) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.panelClass = 'background-color-transparent';
        dialogConfig.data = userId;

        this.iframeService.openComponent('UserDetailsComponent');
        const ref: MatDialogRef<UserDetailsComponent> = this.dialog.open(
            UserDetailsComponent,
            dialogConfig
        );
        ref.componentInstance.emitOpenWarningDialog.subscribe((warning: string) => {
            this.openWarningDialog(warning).subscribe((status: boolean) => {
                ref.componentInstance.getOpenWarningDialog.next(status);
            });
        });

        ref.componentInstance.emitDeleteUser.subscribe((user) => {
            this.openConfirmationDeleteUser(user.id).then((promise) => {
                promise.subscribe(() => {
                    ref.close();
                    window.parent.postMessage({ userdeleted: userId }, '*');

                    this.flashMessageService.flash("L'utilisateur a bien été supprimé");
                });
            });
        });
        ref.afterClosed().subscribe(() => {
            this.iframeService.closeComponent('UserDetailsComponent');
        });
    }

    async openConfirmationDeleteUser(userId) {
        const { ConfirmationDeleteUserComponent } = await import(
            '../dialogs/confirmation-delete-user/confirmation-delete-user.component'
        );

        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = userId;

        const ref: MatDialogRef<any> = this.dialog.open(
            ConfirmationDeleteUserComponent,
            dialogConfig
        );
        return ref.afterClosed();
    }

    async openUserHistory(data: { user: User; startDate?: string; endDate?: string }) {
        const { UserHistoryComponent } = await import(
            '../dialogs/user-history/user-history.component'
        );

        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '500px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = data;

        this.iframeService.openComponent('UserHistoryComponent');
        const ref: MatDialogRef<any> = this.dialog.open(UserHistoryComponent, dialogConfig);
        ref.afterClosed().subscribe(() => {
            this.iframeService.closeComponent('UserHistoryComponent');
        });
    }

    async openCall(data: any) {
        const { CallComponent } = await import('../dialogs/call/call.component');

        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '500px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = data;

        this.iframeService.openComponent('CallComponent');
        const ref: MatDialogRef<any> = this.dialog.open(CallComponent, dialogConfig);
        ref.afterClosed().subscribe(() => {
            this.iframeService.closeComponent('CallComponent');
        });
        this.notificationService.closeCurrentCall.subscribe(() => {
            ref.close();
        });
    }

    openChat(data: any) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '400px';
        dialogConfig.height = '500px';
        dialogConfig.maxWidth = '100vw';
        dialogConfig.maxHeight = '100vh';
        dialogConfig.data = data;

        this.iframeService.openComponent('ChatComponent');
        const ref: MatDialogRef<any> = this.dialog.open(ChatComponent, dialogConfig);
        ref.componentInstance.openErrorDialog.subscribe((message) => {
            this.openErrorDialog(message);
        });
        ref.afterClosed().subscribe(() => {
            this.iframeService.closeComponent('ChatComponent');
        });
    }

    openWarningDialog(content?: String) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        if (content) {
            dialogConfig.data = content;
        }

        this.iframeService.openComponent('WarningComponent');
        const ref: MatDialogRef<WarningComponent> = this.dialog.open(
            WarningComponent,
            dialogConfig
        );
        ref.afterClosed().subscribe(() => {
            this.iframeService.closeComponent('WarningComponent');
        });
        return ref.afterClosed();
    }
}
