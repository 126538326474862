import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingComponent } from 'src/app/components/loading/loading.component';
import { FlashMessageComponent } from 'src/app/components/flash-message/flash-message.component';

@NgModule({
    declarations: [FlashMessageComponent, LoadingComponent],
    imports: [CommonModule],
    exports: [FlashMessageComponent, LoadingComponent]
})
export class LoadingModule {}
