// Internal dependencies
import { Component, OnInit, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

// Modules
import { LoadingModule } from 'src/app/modules/loading.module';

// Components

// Services
import { LoadingService } from 'src/app/services/loading.service';
import { UserService } from 'src/app/services/user.service';
import { GroupService } from 'src/app/services/group.service';

// Interfaces
import { User } from 'src/app/structures/user';
import { Group } from 'src/app/structures/group';

interface TutorUsersProps {
    viewOnly: boolean;
    structureid: number;
    tutorUsers: Array<User>;
    tutorGroups: Array<Group>;
}

// Pipes

/**
 *  fenetre de dialogue pour la deconnexion.
 */
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-tutor-users',
    templateUrl: './tutor-users.component.html',
    styleUrls: ['./tutor-users.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        LoadingModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        InfiniteScrollDirective
    ]
})
export class TutorUsersComponent implements OnInit {
    search: string = '';

    users: Array<User>;
    groups: Array<Group>;

    currentPageUsers: number;
    currentPageGroups: number;

    LIMIT = 30;

    showDisabledUsers: boolean = false;

    constructor(
        private dialogRef: MatDialogRef<TutorUsersComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TutorUsersProps,
        private loadingService: LoadingService,
        private userService: UserService,
        private groupService: GroupService
    ) {}

    ngOnInit() {
        if (!this.data.viewOnly) {
            this.refreshData();
        }
    }

    // #region Getters

    getUsers() {
        this.currentPageUsers++;
        const params: any = {
            structureid: this.data.structureid,
            role: 'learner|prospect',
            search: this.search,
            offset: this.currentPageUsers * this.LIMIT,
            limit: this.LIMIT
        };
        if (!this.showDisabledUsers) {
            params.enabled = true;
        }

        if (this.currentPageUsers === 0) {
            this.loadingService.startLoading('userDetails', 'getUsers');
        } else {
            this.loadingService.startLoading('userDetails', 'getUsersNextPage');
        }
        this.userService.getUsers(params).subscribe(
            (data: User[]) => {
                this.users = this.users.concat(
                    data.filter((user: User) => {
                        if (
                            this.data.tutorUsers.findIndex(
                                (tutorUser: User) => tutorUser.id === user.id
                            ) > -1
                        ) {
                            return false;
                        }
                        return true;
                    })
                );

                this.loadingService.stopLoading('userDetails', 'getUsers');
                this.loadingService.stopLoading('userDetails', 'getUsersNextPage');
            },
            (error: HttpErrorResponse) => {
                this.loadingService.stopLoading('userDetails', 'getUsers');
                this.loadingService.stopLoading('userDetails', 'getUsersNextPage');
            }
        );
    }

    getGroups() {
        this.currentPageGroups++;
        const params = {
            structureid: this.data.structureid,
            type: 'learner',
            search: this.search,
            offset: this.currentPageGroups * this.LIMIT,
            limit: this.LIMIT
        };

        if (this.currentPageGroups === 0) {
            this.loadingService.startLoading('userDetails', 'getGroups');
        } else {
            this.loadingService.startLoading('userDetails', 'getGroupsNextPage');
        }
        this.groupService.getGroups(params).subscribe(
            (data: Array<Group>) => {
                this.groups = this.groups.concat(
                    data.filter((group: Group) => {
                        if (
                            this.data.tutorGroups.findIndex(
                                (tutorGroup: Group) => tutorGroup.id === group.id
                            ) > -1
                        ) {
                            return false;
                        }
                        return true;
                    })
                );

                this.loadingService.stopLoading('userDetails', 'getGroups');
                this.loadingService.stopLoading('userDetails', 'getGroupsNextPage');
            },
            (error: HttpErrorResponse) => {
                this.loadingService.stopLoading('userDetails', 'getGroups');
                this.loadingService.stopLoading('userDetails', 'getGroupsNextPage');
            }
        );
    }

    getUserIcon(user) {
        return user.roles.learner ? 'icon-apprenant' : 'icon-apprenant-prospect';
    }

    isLoading(view?: string) {
        if (view) {
            return this.loadingService.isLoading('userDetails', view);
        } else {
            return this.loadingService.isLoading('userDetails');
        }
    }

    // #endregion Getters

    // #region Handlers

    refreshData(clear?: boolean) {
        if (clear) {
            this.search = '';
        }
        this.currentPageUsers = -1;
        this.users = [];
        this.getUsers();

        this.currentPageGroups = -1;
        this.groups = [];
        this.getGroups();
    }

    selectUser(user: User) {
        this.data.tutorUsers.push(
            this.users.splice(
                this.users.findIndex((tutorUser: User) => tutorUser.id === user.id),
                1
            )[0]
        );
        if (this.users.length < 10) {
            this.getUsers();
        }
    }

    selectGroup(group: Group) {
        this.data.tutorGroups.push(
            this.groups.splice(
                this.groups.findIndex((tutorGroup: User) => tutorGroup.id === group.id),
                1
            )[0]
        );
        if (this.groups.length < 10) {
            this.getGroups();
        }
    }

    unselectUser(tutorUser: User) {
        this.users.unshift(
            this.data.tutorUsers.splice(
                this.data.tutorUsers.findIndex((user: User) => user.id === tutorUser.id),
                1
            )[0]
        );
    }

    unselectGroup(tutorGroup: Group) {
        this.groups.unshift(
            this.data.tutorGroups.splice(
                this.data.tutorGroups.findIndex((user: User) => user.id === tutorGroup.id),
                1
            )[0]
        );
    }

    handleCloseDialog() {
        this.dialogRef.close({ users: this.data.tutorUsers, groups: this.data.tutorGroups });
    }

    toggleDisabledUsers() {
        this.showDisabledUsers = !this.showDisabledUsers;
        this.refreshData();
    }

    // #endregion Handlers
}
