import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { NotificationService } from '../../services/notification.service';
import { DialogService } from '../../services/dialog.service';
import { IframeService } from '../../services/iframe.service';
import { ConfigService } from '../../services/config.service';
import { ApplicationService } from '../../services/application.service';
import { LoginService } from '../../services/login.service';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Application } from 'src/app/structures/application';

/**
 *  composant qui gere les notifications ainsi que le menu qui y est dedie dans l'application.
 */
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    standalone: false
})
export class NotificationComponent implements OnInit {
    /**
     * @param {number} limit la limite d'affichage des notifications a chaque scroll.
     * @param {string} searchText le texte a rechercher entre dans le champ dedie.
     * @param {any} notifications le tableau de notifications de l'utilisateur.
     * @param {number} currentPageNotif la page de notifications dans laquelle on se trouve.
     * @param {number} nbNotification le nombre de notifications non lues.
     * @param {number} selectedNotification l'id de la notification selectionnee.
     */
    limit = 30;
    notifications: any;
    nbNotification: number;
    selectedNotification: number;
    searchText: string;
    currentPageNotif = 1;
    unreadNotification: boolean;
    applications: Application[];

    subscriptions = new Subscription();

    constructor(
        private dialogService: DialogService,
        private notificationService: NotificationService,
        private applicationService: ApplicationService,
        private iframeService: IframeService,
        private loginService: LoginService,
        private configService: ConfigService
    ) {}

    isOpened(): boolean {
        return this.iframeService.isOpenedComponent('NotificationComponent');
    }

    /**
     *  ouvre le menu des notifications.
     * @returns {void}
     */
    closeNotificationMenu(): void {
        this.iframeService.closeComponent('NotificationComponent');
    }

    /**
     * @returns {any} le nombre de notifications non lues.
     */
    getNbNotification(): any {
        if (this.nbNotification === 0) {
            return null;
        } else if (this.nbNotification < 99) {
            return this.nbNotification;
        } else if (this.nbNotification) {
            return '++';
        }
    }

    /**
     * @returns {string} la couleur de l'application demandee.
     */
    getApplicationColor(): string {
        const currentApp = this.applicationService.getCurrentApplication();

        if (currentApp && currentApp.color) {
            return currentApp.color;
        } else {
            return '#424242';
        }
    }

    /**
     *  gere l'affichage des notifications suivantes lors du scroll
     * via la fonction associee dans notificationService.
     * @returns {void}
     */
    nextNotification(): void {
        this.currentPageNotif++;
        const params: any = {};

        params.id = this.notifications[this.notifications.length - 1].id;
        params.search = this.searchText ? this.searchText : '';
        params.limit = this.currentPageNotif * this.limit;
        this.subscriptions.add(
            this.notificationService.getMyNotifications(params).subscribe((data) => {
                this.notifications = this.notifications.concat(data);
            })
        );
    }

    /**
     *  supprime une notification.
     * @param idNotification l'id de la notification a supprimer.
     * @returns {void}
     */
    deleteNotification(idNotification: number): void {
        let id: string;

        for (const i in this.notifications) {
            if (this.notifications[i].id === idNotification) {
                id = i;
            }
        }
        this.notifications.splice(id, 1);
    }

    /**
     *  fonction de recherche d'une notification via la fonction associee dans notificationService.
     * @returns {void}
     */
    researchNotification(): void {
        const params: any = {};

        params.search = this.searchText ? this.searchText : '';
        this.subscriptions.add(
            this.notificationService.getMyNotifications(params).subscribe((data: any) => {
                this.notifications = data;
            })
        );
    }

    hasUnreadNotifications(): boolean {
        return this.unreadNotification;
    }

    hasNotifications(): boolean {
        if (this.notifications) {
            return this.notifications.length > 0;
        }
    }

    /**
     *  marque toutes les notifications comme lues.
     * @returns {void}
     */
    markAllAsRead(): void {
        if (!this.hasUnreadNotifications()) {
            return;
        }

        this.subscriptions.add(
            this.notificationService.markAllAsRead().subscribe(() => {
                this.notificationService.emitAllNotificationsRead();
            })
        );
    }

    /**
     *  marque toutes les notifications comme lues.
     * @returns {void}
     */
    deleteAllNotifications(): void {
        if (!this.hasNotifications()) {
            return;
        }
        this.subscriptions.add(
            this.notificationService.deleteAllNotifications().subscribe(() => {
                this.notificationService.emitAllNotificationsDeleted();
            })
        );
    }

    /**
     *  marque seulement une notification comme selectionnee sans la retourner.
     * @param idNotification l'id de la notification.
     * @returns {void}
     */
    selectNotification(idNotification: number): void {
        this.selectedNotification = idNotification;
    }

    /**
     *  marque la notification envoyee comme selectionnee et la retourne.
     * @param idNotification l'id de la notification.
     * @returns {any} la notification selectionnee.
     */
    isSelectedNotification(idNotification: number): any {
        return this.selectedNotification === idNotification;
    }

    manageNotifications() {
        this.iframeService.isOpenedComponent('NotificationsManagerComponent')
            ? this.iframeService.closeComponent('NotificationsManagerComponent')
            : this.iframeService.openComponent('NotificationsManagerComponent');
        this.iframeService.closeComponent('NotificationComponent');
    }

    canManageNotifications(): boolean {
        return !this.configService.getConfig().disable_notifications_management;
    }

    ngOnInit() {
        this.unreadNotification = false;

        const params = {
            offset: 0,
            limit: 30
        };

        if (this.loginService.getUser()) {
            setTimeout(() => {
                this.subscriptions.add(
                    this.notificationService.getMyNotifications(params).subscribe((data: any) => {
                        this.notifications = data;
                    })
                );
            }, this.configService.getConfig().notification_delay * 1000);
        }

        if (this.loginService.getUser()) {
            this.subscriptions.add(
                this.notificationService.hasUnreadNotifications().subscribe((data: boolean) => {
                    this.unreadNotification = data;
                })
            );

            this.subscriptions.add(
                this.notificationService.newNotificationReceived.subscribe((notification: any) => {
                    if (!notification.duplicate) {
                        this.notifications.unshift(notification);
                        this.unreadNotification = true;
                    }
                })
            );
        }
        if (this.loginService.getUser()) {
            this.subscriptions.add(
                this.applicationService
                    .getApplications()
                    .subscribe((applications: Array<Application>) => {
                        this.applications = applications;
                    })
            );
        }

        this.subscriptions.add(
            this.notificationService.notificationRead.subscribe((notification: any) => {
                for (const i in this.notifications) {
                    if (this.notifications[i].id === notification.id) {
                        this.notifications[i].markAsRead = true;
                    }
                }
                this.subscriptions.add(
                    this.notificationService.hasUnreadNotifications().subscribe((data: boolean) => {
                        this.unreadNotification = data;
                    })
                );
            })
        );

        this.subscriptions.add(
            this.notificationService.notificationDeleted.subscribe((notification: any) => {
                for (const i in this.notifications) {
                    if (this.notifications[i].id === notification.id) {
                        this.notifications.splice(i, 1);
                    }
                }
            })
        );

        this.subscriptions.add(
            this.notificationService.allNotificationRead.subscribe(() => {
                for (const i in this.notifications) {
                    if (!this.notifications[i].markAsRead) {
                        this.notifications[i].markAsRead = true;
                    }
                }
                this.unreadNotification = false;
            })
        );

        this.subscriptions.add(
            this.notificationService.allNotificationDeleted.subscribe(() => {
                this.notifications = [];
            })
        );
    }
}
