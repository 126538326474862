<div class="applications-container" [ngClass]="{ opened: isOpened() }">
    @for (section of sections; track section.name) {
        <div class="section">
            <div class="title">{{ section.name }}</div>
            @for (application of section.applications; track application.id) {
                <app-application [application]="application"></app-application>
            }

            <div class="separator"></div>
        </div>
    }
</div>
