import { Component, OnInit } from '@angular/core';
import { IframeService } from 'src/app/services/iframe.service';
import { Subscription } from 'rxjs';

import { NotificationService } from 'src/app/services/notification.service';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { ApplicationService } from 'src/app/services/application.service';
import { LoginService } from 'src/app/services/login.service';

import { AppNotificationsSettings } from 'src/app/structures/app-notifications-settings';
import { NotificationsChannel } from 'src/app/structures/notification-channel';
import { Application } from 'src/app/structures/application';

import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-notifications-manager',
    templateUrl: './notifications-manager.component.html',
    styleUrls: ['./notifications-manager.component.scss'],
    standalone: false
})
export class NotificationsManagerComponent implements OnInit {
    subscriptions: Subscription = new Subscription();
    myChannelsSettings: AppNotificationsSettings[];
    applications: any[];

    constructor(
        private iframeService: IframeService,
        private loginService: LoginService,
        private notificationService: NotificationService,
        private flashMessageService: FlashMessageService,
        private applicationService: ApplicationService
    ) {}

    ngOnInit() {
        if (this.loginService.getUser()) {
            this.subscriptions.add(
                this.applicationService
                    .getApplications()
                    .subscribe((applications: Array<Application>) => {
                        this.applications = applications;
                    })
            );
            this.subscriptions.add(
                this.notificationService
                    .getMyChannels()
                    .subscribe((data: AppNotificationsSettings[]) => {
                        this.myChannelsSettings = data;
                    })
            );
        }
    }

    isOpened() {
        return this.iframeService.isOpenedComponent('NotificationsManagerComponent');
    }

    getComponentClasses(): string {
        return this.iframeService.isOpenedComponent('NotificationsManagerComponent')
            ? 'opened'
            : '';
    }

    getAppIcon(app: AppNotificationsSettings) {
        if (this.applications.some((application) => application.app_id === app.app_id)) {
            return this.applications.find((application) => application.app_id === app.app_id)
                .icon_font;
        }
        return null;
    }

    getAppImage(app: AppNotificationsSettings) {
        if (this.applications.some((application) => application.app_id === app.app_id)) {
            return this.applications.find((application) => application.app_id === app.app_id)
                .icon_png;
        }
        return null;
    }

    getAppName(app: AppNotificationsSettings) {
        if (this.applications.some((application) => application.app_id === app.app_id)) {
            return this.applications.find((application) => application.app_id === app.app_id).name;
        }
        return null;
    }

    getApplicationColor(app: AppNotificationsSettings) {
        if (this.applications.some((application) => application.app_id === app.app_id)) {
            return this.applications.find((application) => application.app_id === app.app_id).color;
        }
        return '#000000';
    }

    getAppObjectName(application: AppNotificationsSettings): string {
        switch (application.app_id) {
            case 'easichat':
                return 'chat';
            case 'easicall':
                return 'call';
            case 'easicloud':
                return 'cloud';
            case 'easiforum':
                return 'forum';
            default:
                return application.app_id;
        }
    }

    /**
     * affiche la note 'vous pouvez aussi désactiver les notifications d'un ... spécifique...'
     * @param application les settings de l'application
     */
    shouldDisplayAppNote(application: AppNotificationsSettings): boolean {
        if (
            ['easicall', 'easichat', 'easicloud', 'easiforum', 'easinews'].includes(
                application.app_id
            )
        ) {
            return true;
        }
        return false;
    }

    getAppNote(application: AppNotificationsSettings): string {
        if (application.app_id === 'easinews') {
            return `Vous ne pouvez pas désactiver ces notifications dans <strong>easi</strong>`;
        }
        return `Vous pouvez aussi désactiver les notifications d'un ${this.getAppObjectName(
            application
        )} spécifique via <strong>${this.getAppName(application)}`;
    }

    /**
     * toggle on/off de tous les canaux de l'application concernée
     * @param appSettings les settings de l'application
     */
    toggleAllWebNotifications($event: Event, appSettings: AppNotificationsSettings): void {
        $event.stopImmediatePropagation();
        const tmp: string[] = appSettings.channels.map((channel) => channel.user_settings.via_web);
        const hasUnSelected: boolean = tmp.some((item) => item === 'never');
        if (hasUnSelected) {
            appSettings.channels = appSettings.channels.map((channel) => ({
                ...channel,
                user_settings: {
                    ...channel.user_settings,
                    via_web: 'immediate'
                }
            }));
        } else {
            appSettings.channels = appSettings.channels.map((channel) => ({
                ...channel,
                user_settings: {
                    ...channel.user_settings,
                    via_web: 'never'
                }
            }));
        }
    }

    toggleAllMobileNotifications($event: Event, appSettings: AppNotificationsSettings): void {
        $event.stopImmediatePropagation();
        const tmp: string[] = appSettings.channels.map((channel) => channel.user_settings.via_web);
        const hasUnSelected: boolean = tmp.some((item) => item === 'never');
        if (hasUnSelected) {
            appSettings.channels = appSettings.channels.map((channel) => ({
                ...channel,
                user_settings: {
                    ...channel.user_settings,
                    via_mobile: 'immediate'
                }
            }));
        } else {
            appSettings.channels = appSettings.channels.map((channel) => ({
                ...channel,
                user_settings: {
                    ...channel.user_settings,
                    via_mobile: 'never'
                }
            }));
        }
    }

    /**
     * Passe tous les canaux mail de l'app concernée à la fréquence choisie
     * @param $event l'event
     * @param appSettings l'objet appSettings (réglages de l'applicaiton)
     * @param frequency la fréquence cliquée
     */
    toggleAllMailNotifications(
        $event: Event,
        appSettings: AppNotificationsSettings,
        frequency: 'immediate' | 'daily' | 'weekly'
    ): void {
        $event.stopImmediatePropagation();
        const tmp: string[] = appSettings.channels.map((channel) => channel.user_settings.via_mail);
        const hasUnSelected: boolean = tmp.some((item) => item !== frequency);
        if (hasUnSelected) {
            appSettings.channels = appSettings.channels.map((channel) => ({
                ...channel,
                user_settings: {
                    ...channel.user_settings,
                    via_mail: frequency
                }
            }));
        } else {
            appSettings.channels = appSettings.channels.map((channel) => ({
                ...channel,
                user_settings: {
                    ...channel.user_settings,
                    via_mail: 'never'
                }
            }));
        }
    }

    /**
     * active/désactive les notifications DANS EASI du canal
     * @param channel le canal choisi
     */
    toggleWebNotificationsSelector(channel: NotificationsChannel): void {
        if (channel.user_settings.via_web === 'immediate') {
            channel.user_settings.via_web = 'never';
        } else {
            channel.user_settings.via_web = 'immediate';
        }
    }

    toggleMobileNotificationsSelector(channel: NotificationsChannel): void {
        if (channel.user_settings.via_mobile === 'immediate') {
            channel.user_settings.via_mobile = 'never';
        } else {
            channel.user_settings.via_mobile = 'immediate';
        }
    }

    /**
     * retourne les classes CSS correspondant aux icones des selecteurs 'dans easi' pour les applications
     * @param appSettings l'applicationSettings concerné
     */
    getApplicationViaWebSelectorIcon(
        appSettings: AppNotificationsSettings
    ): 'icon-select' | 'icon-selected' | 'icon-selected grey-icon' {
        const tmp: string[] = appSettings.channels.map((channel) => channel.user_settings.via_web);
        const hasSelected: boolean = tmp.some((item) => item === 'immediate');
        const hasUnSelected: boolean = tmp.some((item) => item === 'never');
        if (hasSelected && hasUnSelected) {
            return 'icon-selected grey-icon';
        } else if (hasSelected) {
            return 'icon-selected';
        } else {
            return 'icon-select';
        }
    }

    getApplicationViaMobileSelectorIcon(
        appSettings: AppNotificationsSettings
    ): 'icon-select' | 'icon-selected' | 'icon-selected grey-icon' {
        const tmp: string[] = appSettings.channels.map(
            (channel) => channel.user_settings.via_mobile
        );
        const hasSelected: boolean = tmp.some((item) => item === 'immediate');
        const hasUnSelected: boolean = tmp.some((item) => item === 'never');
        if (hasSelected && hasUnSelected) {
            return 'icon-selected grey-icon';
        } else if (hasSelected) {
            return 'icon-selected';
        } else {
            return 'icon-select';
        }
    }

    /**
     * retourne les classes CSS correspondant aux icones des selecteurs 'Email' pour les applications
     * @param appSettings l'applicationSettings concerné
     * @param frequency la fréquence dont il est question
     */
    getApplicationViaMailSelectorIcon(
        appSettings: AppNotificationsSettings,
        frequency: 'immediate' | 'daily' | 'weekly'
    ): 'icon-select' | 'icon-selected' | 'icon-selected grey-icon' {
        const tmp: string[] = appSettings.channels.map((channel) => channel.user_settings.via_mail);
        const hasNot: boolean = tmp.some((item) => item !== frequency);
        if (hasNot) {
            const has: boolean = tmp.some((item) => item === frequency);
            if (has) {
                return 'icon-selected grey-icon';
            }
            return 'icon-select';
        }
        return 'icon-selected';
    }

    setMailChannel(
        channel: NotificationsChannel,
        frequency: 'immediate' | 'daily' | 'weekly'
    ): void {
        if (channel.user_settings.via_mail === frequency) {
            channel.user_settings.via_mail = 'never';
        } else {
            channel.user_settings.via_mail = frequency;
        }
    }

    saveSettings(): void {
        this.subscriptions.add(
            this.notificationService.setMyChannels(this.myChannelsSettings).subscribe(() => {
                this.flashMessageService.flash('Vos modifications ont été enregistrées.');
            })
        );
    }

    close(): void {
        this.iframeService.closeComponent('NotificationsManagerComponent');
    }
}
