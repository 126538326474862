<div
    class="application"
    [ngClass]="{ current: isActiveApp() }"
    [ngStyle]="{ color: application().color }"
>
    @if (application().icon_font) {
        <span class="icon {{ application().icon_font }}"></span>
    } @else if (application().icon_png) {
        <img [src]="'data:image/png;base64,' + application().icon_png" class="icon" />
    }
    <a class="name" [href]="getApplicationURL()" target="_parent">
        {{ getApplicationName() }}
    </a>
    @if (application().children) {
        <div class="icon-down"></div>
        <div class="application-children">
            @for (child of application().children; track child.id) {
                <app-application [application]="child" [isChild]="true"></app-application>
            }
        </div>
    } @else {
        <a
            [href]="getApplicationURL()"
            [tooltip]="'Ouvrir dans un nouvel onglet'"
            [tooltipColor]="'#fff'"
            [tooltipTextColor]="application().color"
            target="_blank"
            class="icon icon-multi-ecran-bis"
        ></a>
    }
</div>
