import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EasiHttpParams } from '../interceptors/easi-http-params';

import { ApplicationService } from './application.service';

import { log } from './decorators/log.decorator';

/**
 *  service gerant l'utilisateur actuel dans l'application.
 */
@Injectable({
    providedIn: 'root'
})
export class ChatService {
    backendUrl: string;
    loginUrl: string;
    frontendUrl: string;

    constructor(
        private http: HttpClient,
        private applicationService: ApplicationService
    ) {
        setTimeout(() => {
            this.applicationService.getApplications().subscribe((applications) => {
                this.backendUrl = applications.filter(
                    (application) => application.app_id === 'easichat'
                )[0].backend_url;
                this.loginUrl = applications.filter(
                    (application) => application.app_id === 'easichat'
                )[0].login_url;
                this.frontendUrl = applications.filter(
                    (application) => application.app_id === 'easichat'
                )[0].frontend_url;
            });
        });
    }

    @log() getCurrentUserChat(): Observable<any> {
        return this.http.get(this.getChatBackendEndPoint() + '/user/current', {
            params: new EasiHttpParams({
                overrideBaseUrl: true,
                noError: true
            })
        });
    }

    @log() initDiscussion(user: any): Observable<any> {
        const params = {
            user_ids: user.id
        };

        return this.http.get(this.getChatBackendEndPoint() + '/discussions/with', {
            params: new EasiHttpParams({
                overrideBaseUrl: true,
                params: {
                    ...params
                }
            })
        });
    }

    @log() getDiscussion(discussionId: number): Observable<any> {
        return this.http.get(this.getChatBackendEndPoint() + '/discussions/' + discussionId, {
            params: new EasiHttpParams({
                overrideBaseUrl: true
            })
        });
    }

    @log() sendMessage(discussionid: number, message: string): Observable<any> {
        return this.http.post(
            this.getChatBackendEndPoint() + '/messages',
            { message, discussionid },
            {
                params: new EasiHttpParams({
                    overrideBaseUrl: true
                })
            }
        );
    }

    @log() getMessages(nbMessages: number, discussionId: number, offset?: number): Observable<any> {
        const params: any = {
            limit: nbMessages
        };
        if (offset) {
            params.messageid = offset;
        }

        return this.http.get(this.getChatBackendEndPoint() + '/messages/' + discussionId, {
            params: new EasiHttpParams({
                overrideBaseUrl: true,
                params: {
                    ...params
                }
            })
        });
    }

    @log() createAttachment(messageId: number, file: File): Observable<any> {
        const body = new FormData();
        body.append('attachment', file);

        return this.http.post(
            this.getChatBackendEndPoint() + '/messages/' + messageId + '/attachments',
            body,
            {
                params: new EasiHttpParams({
                    overrideBaseUrl: true
                })
            }
        );
    }

    getChatBackendEndPoint() {
        return this.backendUrl;
    }

    getChatLoginEndPoint() {
        return this.loginUrl;
    }

    getChatFrontEndPoint() {
        return this.frontendUrl;
    }
}
