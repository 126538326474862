<div class="header">
    <div class="icon-tuteurentreprise"></div>
    <div class="label">Apprenants liés au tuteur</div>
    <button mat-icon-button (click)="handleCloseDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="content">
    <div class="current-tutor-users" [class.full-display]="data.viewOnly">
        <div class="label">Utilisateurs et groupes liés à ce tuteur</div>
        <div class="users-container">
            @for (tutorUser of data.tutorUsers; track tutorUser) {
                <div class="user" [class.disabled]="!tutorUser.enabled">
                    <div [ngClass]="getUserIcon(tutorUser)"></div>
                    <div class="user-name">
                        {{ tutorUser.firstname }} {{ tutorUser.lastname.toUpperCase() }}
                    </div>
                    @if (!data.viewOnly) {
                        <div class="icon-selected" (click)="unselectUser(tutorUser)"></div>
                    }
                </div>
            }
            @for (tutorGroup of data.tutorGroups; track tutorGroup) {
                <div class="user">
                    <div class="icon-groupe"></div>
                    <div class="user-name">{{ tutorGroup.name }}</div>
                    @if (!data.viewOnly) {
                        <div class="icon-selected" (click)="unselectGroup(tutorGroup)"></div>
                    }
                </div>
            }
        </div>
    </div>
    @if (!data.viewOnly) {
        <div class="possible-container">
            <div class="right-header">
                <mat-checkbox (change)="toggleDisabledUsers()"
                    >Afficher les utilisateurs désactivés</mat-checkbox
                >

                <mat-form-field class="search-form-field">
                    <mat-label>Rechercher</mat-label>
                    <input
                        matInput
                        type="text"
                        [(ngModel)]="search"
                        (keyup.enter)="refreshData()"
                    />
                    @if (search) {
                        <button matSuffix mat-icon-button (click)="refreshData(true)">
                            <mat-icon>close</mat-icon>
                        </button>
                    }
                    <button matSuffix mat-icon-button (click)="refreshData()">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div class="possible-tutor-users">
                <div class="label">Utilisateurs pouvant être liés à ce tuteur</div>
                @if (isLoading('getUsers')) {
                    <app-loading></app-loading>
                } @else {
                    <div
                        class="users-container"
                        infiniteScroll
                        [infiniteScrollDistance]="2"
                        [infiniteScrollThrottle]="50"
                        [scrollWindow]="false"
                        (scrolled)="getUsers()"
                    >
                        @for (user of users; track user) {
                            <div class="user" [class.disabled]="!user.enabled">
                                <div [ngClass]="getUserIcon(user)"></div>
                                <div class="user-name">
                                    {{ user.firstname }} {{ user.lastname.toUpperCase() }}
                                </div>
                                <div class="icon-select" (click)="selectUser(user)"></div>
                            </div>
                        }
                        @if (isLoading('getUsersNextPage')) {
                            <div class="loading-container">
                                <app-loading></app-loading>
                            </div>
                        }
                    </div>
                }
            </div>

            <div class="possible-tutor-groups">
                <div class="label">Groupes pouvant être liés à ce tuteur</div>

                @if (isLoading('getGroups')) {
                    <app-loading></app-loading>
                } @else {
                    <div
                        class="users-container"
                        infiniteScroll
                        [infiniteScrollDistance]="2"
                        [infiniteScrollThrottle]="50"
                        [scrollWindow]="false"
                        (scrolled)="getGroups()"
                    >
                        @for (group of groups; track group) {
                            <div class="user">
                                <div class="icon-groupe"></div>
                                <div class="user-name">{{ group.name }}</div>
                                <div class="icon-select" (click)="selectGroup(group)"></div>
                            </div>
                        }
                        @if (isLoading('getGroupsNextPage')) {
                            <div class="loading-container">
                                <app-loading></app-loading>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    }
</mat-dialog-content>
@if (!data.viewOnly) {
    <div class="footer">
        <button class="button" mat-button (click)="handleCloseDialog()">Valider</button>
    </div>
}
